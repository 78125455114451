import { inject } from 'core';
import { WellnessMessages } from 'elsie/config/wellness-messages';
import { DateWrapper, createFwlink } from 'elsie/utils';
import { addDays } from 'date-fns';

const DefaultTileOptions = {
    show: true
};

const Tiles = [
    { name: 'Planner', state: 'planner.calendar' },
    { name: 'Units', state: 'units.overview' },
    { name: 'Assessments', state: 'assessments.overview.upcoming' },
    { name: 'iLectures', icon: 'play-outline', href: ((context) => createFwlink(context.user, 'ilectures')) },
    { name: 'Map' },
    { name: 'Progress', state: 'progress.overview' },
    { name: 'Contacts', state: 'contacts.list' },
    { name: 'Library' },
    { name: 'Support', icon: 'info', state: 'support.contacts' },
    { name: 'Feedback' },
];

Tiles.push({ name: 'mocking', icon: 'planner' });

const WeekDateTypeCode = 'WK';
const RelevantDateTypeCodes = [ WeekDateTypeCode, 'SWK', 'TFW', 'OW', 'EXP', 'TW', 'SBRK' ];
const DaysAfterExamFinished = 7;

@inject('$scope', '$interval', '$q', '$state', 'SessionService', 'NotificationService', 'ReminderService', 'AppInsights', 'ViewService', 'CommunicationsService', 'TutorialService')
export class DashboardView {
    constructor(scope, interval, promise, state, sessionService, notificationService, reminderService, appInsights, viewService, communicationsService, tutorialService) {
        this.scope = scope;
        this.interval = interval;
        this.promise = promise;
        this.state = state;
        this.user = sessionService.current();
        this.notificationService = notificationService;
        this.reminderService = reminderService;
        this.appInsights = appInsights;
        this.communicationsService = communicationsService;
        this.tutorialService = tutorialService;
        
        this.$viewPromise = viewService.wrap(this.$init());
    }

    $init() {
        this.date = DateWrapper.new();
        this.dateFormat = `EEEE d'${getDaySuffix(this.date.getDate())}' MMMM`;

        this.tiles = this.$getTiles();
        this.$addWellnessMessageInterval(this.user);

        const handleFailure = this.appInsights.createErrorHandler('Dashboard.$init');

        // Deliberately don't return these promises, they'll load in the background
        this.reminderService.getReminders(this.user.curtinId, this.date)
            .then(reminders => this.reminders = (reminders.length <= 3 ? reminders : reminders.slice(0, 3)), handleFailure);

        this.communicationsService.getStudentNotificationsOverview(this.user.curtinId)
            .then(overview => {
                this.urgentAlertCount = overview.alerts.urgentCount;
                this.tutorialService.getTutorial(steps => overview.alerts.urgentCount == 0 ? steps.filter(s => s.name != 'urgent-alert') : steps)
                    .then(tutorial => this.tutorial = tutorial);
            }, handleFailure);
    }

    logout() {
        this.state.go('logout');
    }

    $addWellnessMessageInterval(user) {
        const updateMessage = () => {
            const index = Math.floor((new Date().getTime())/10000) % WellnessMessages.length;
            const message = WellnessMessages[index].replace(/\%1\$s/g, user.preferredName);

            this.showWellnessMessageEven = index % 2 === 0;
            this.showWellnessMessageEven
                ? this.wellnessMessageEven = message
                : this.wellnessMessageOdd = message;
        }

        updateMessage();
        const int = this.interval(updateMessage, 1000*30);

        this.scope.$on('$destroy', () => this.interval.cancel(int));
    }

    $getTiles() { 
        return Tiles.map(options => {
            const tile = Object.assign({}, DefaultTileOptions);
            const resolveOption = options['$resolve'];

            Object.keys(options).forEach(key => {
                if (key !== '$resolve')
                    tile[key] = getOrInvoke(options[key], this);
            });

            if (resolveOption != null) {
                // Handle the 'resolve' object in a special way
                const resolve = Object.keys(resolveOption).reduce((obj, key) => {
                    obj[key] = getOrInvoke(resolveOption[key], this);
                    return obj;
                }, {});

                this.promise.all(resolve).then(values => Object.assign(tile, values));
            }

            return tile;
        });
    }
}

function getDaySuffix(n) {
    switch(n) {
    case 1:
    case 21:
    case 31:
        return 'st';
    case 2:
    case 22:
        return 'nd';
    case 3:
    case 23:
        return 'rd';
    default:
        return 'th';
    }
}

function getWeek(entries) {
    const weekNumbers = [];
    let relevantEntry = null;

    entries && entries.forEach(entry => {
        if (entry.dateTypeCode === WeekDateTypeCode)
            weekNumbers.push(entry.dateType);
        else if (!relevantEntry)
            relevantEntry = entry;
    });

    if (weekNumbers.length <= 0)
        return relevantEntry ? relevantEntry.dateType : 'Not in study period';
    else {
        let str = 'WK ' + weekNumbers.join('/');
        if (relevantEntry)
            str += '. ' + relevantEntry.dateType;
        return str;
    }
}

function getOrInvoke(obj, context) {
    return typeof(obj) === 'function' ? obj(context) : obj;
}