import config from 'config';

const FacultyIdentifierMappings = {
    'sne': 'engineer',
    'hs': 'health',
    'hum': 'human',
    'bal': 'business',
    'cas': 'aboriginal'
};

export * from './collection-fns';
export * from './date-fns';
export * from './ng-fns';
export * from './date-wrapper';

export function isActiveUnit(unit) {
    return unit.isActive;
}

export function getFacultyIdentifier(facultyName) {
    if (!facultyName) 
        return 'default';
        
    const comp = facultyName.toLowerCase();

    const identifier = Object.keys(FacultyIdentifierMappings).find(id => {
        const values = FacultyIdentifierMappings[id];
        return (typeof(values) === 'string' && comp.indexOf(values) >= 0) 
            || (Array.isArray(values) && values.some(v => comp.indexOf(v) >= 0));
    });

    return identifier || 'default';
}

export function toQueryString(obj) {
    return Object.keys(obj).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`).join('&');
}

export function getActivityType(item) {
    switch((item.activityTypeCode|| '').toUpperCase()) {
        case 'LE':
            return 'lecture';
        case 'LB':
        case 'SL':
        case 'CL':
        case 'LL':
            return 'laboratory';
        case  'PR':
            return 'practical';
        case 'WS':
            return 'workshop';
        case 'SE':
            return 'seminar';
        case 'TU':
            return 'tutorial';
        case 'DD':
            return 'due-date';
        case 'O':
            return 'other';
        case 'EX':
            return 'exam';
        default:
            return null;
    }
}

export function createFwlink(user, target) {
    return `${config.urls.api}/forward/${encodeURIComponent(target)}?token=${encodeURIComponent(user.token)}`;
}
