import { inject } from 'core';
import { formatDueDate } from 'elsie/utils';

@inject('$scope', '$interpolate')
class AssessmentListController {
    constructor(scope, interpolate) {
        this.scope = scope;
        this.titleFormat = interpolate(scope.titleFormat || '{{task}}');
        this.formatDueDate = formatDueDate;
    }
}

export function AssessmentListDirective() { }

AssessmentListDirective.prototype = {
    restrict: 'A',
    templateUrl: require('./assessment-list.html'),
    scope: { 
        assessments: '=',
        titleFormat: '='
    },
    controller: AssessmentListController,
    controllerAs: 'vm',
};

