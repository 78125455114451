// Can't @inject functions
RouteConfig.$inject = [ '$stateProvider','$urlRouterProvider','$locationProvider','$urlMatcherFactoryProvider' ];

const DefaultAuthenticationData = {
    authenticate: true,
    roles: [ 'elsie-student', 'staff' ]
};

export function RouteConfig(stateProvider, urlRouterProvider, locationProvider, urlMatcherFactoryProvider) {
    urlMatcherFactoryProvider.caseInsensitive(true);
    locationProvider.html5Mode(true);

    stateProvider
        .state(createState('dashboard', { url: '/' }))

        .state(createBaseState('units'))
            .state(createState('units.details', { url: '/:availabilityId' }))
            .state(createState('units.overview', { url: '/:year/:studyPeriod', params: { year: { squash: true, value: null }, studyPeriod: { squash: true, value: null, dynamic: true } } })) // default route must go last

        .state(createBaseState('my-events'))
            .state(createAliasState('my-events.new', 'my-events.edit'))
            .state(createState('my-events.details', { url: '/:id' }))
            .state(createState('my-events.edit', { url: '/:id/edit' }))

        .state(createBaseState('planner'))
            .state(createState('planner.activity', { url: '/activities/:activityId' }))
            .state(createState('planner.calendar', { url: '/:date', params: { date: { squash: true, value: null, dynamic: true } } }))
            
        .state(createBaseState('contacts'))
            .state(createState('contacts.list', { url: '/units?facultyId', params: { facultyId: null } }))
        .state(createState('contacts.details', { url: '/units/:availabilityId/:staffId', params: { staffId: { squash: true, value: null } } }))

        .state(createBaseState('assessments'))
            .state(createBaseState('assessments.overview', { templateUrl: require('../views/assessments/overview/index.html'), controller: 'AssessmentsOverviewView' }))
                .state(createState('assessments.overview.unit'))
                .state(createState('assessments.overview.upcoming'))
            .state(createState('assessments.details', { url: '/:assessmentId' }))

        .state(createBaseState('progress'))
            .state(createState('progress.details', { url: '/:courseCode/:year/:studyPeriodCode' }))
            .state(createState('progress.overview', { url: '' }))

        .state(createBaseState('sanctions'))
            .state(createState('sanctions.list', { url: '/list' }))
            .state(createState('sanctions.contact', { url: '/contact/:reasonCode' }))

        .state(createState('library'))
        .state(createState('email'))
        .state(createState('map',  { url: '/map?query' }))
        .state(createState('feedback'))
        .state(createState('notice', { url: '/notice/:id' }))
        .state(createState('mocking'))

        .state(createBaseState('support', { templateUrl: require('../views/support/index.html') }))
            .state(createState('support.faqs'))
            .state(createState('support.contacts'))

        .state(createState('report-problem'))

        .state(createBaseState('communications'))
            .state(createBaseState('communications.dashboard', { templateUrl: require('../views/communications/index.html') }))
                .state(createState('communications.dashboard.alerts'))
                .state(createState('communications.dashboard.official-communications'))
            .state(createState('communications.official-communication', { url: '/:userId/:messageId'}));

    urlRouterProvider.otherwise('/');
};

function createBaseState(name, overrides) {
    const parts = name.split('.');
    const url = '/' + kebabise(parts[parts.length - 1]);

    return Object.assign({
        name: name,
        abstract: true,
        template: overrides && overrides.templateUrl ? undefined : '<div ui-view></div>',
        url: url,
        data: DefaultAuthenticationData,
        controllerAs: 'vm'
    }, overrides);
}

function createAliasState(alias, name, overrides) {
    const state = createState(name, overrides);
    state.name = alias;

    if (!overrides || !overrides.url) {
        const parts = alias.split('.');
        state.url = '/' + kebabise(parts[parts.length - 1]);
    }

    return state;
}

function createState(name, overrides) {
    const parts = name.split('.');
    const url = '/' + kebabise(parts[parts.length - 1]);
    const templateUrl = overrides && overrides.templateUrl 
        || require(`../views/${parts.map(kebabise).join('/')}.html`)

        return Object.assign({
            name: name,
            url: url,
            controller: parts.map(pascalise).join('') + 'View',
            controllerAs: 'vm',
            data: DefaultAuthenticationData,
            templateUrl: templateUrl
        }, overrides);
}

function pascalise(name) {
    // this-is--a-name_2 => ThisIsAName2
    return name.charAt(0).toUpperCase() 
        + name.substring(1).replace(/[-_]+(\w)/g, (m, g) => g.toUpperCase());
}

function kebabise(name) {
    // thisIsAName -> this-is-a-name
    return name.replace(/[a-z][A-Z]/g, m => `${m[0]}-${m[1]}`).toLowerCase();
}